$radius: 10px !important;
$height: 50px !important;
$input-border-color: rgba(226, 232, 240, 1);
/*Control wrap*/
.ControlWrap {
  position: relative;
  .labelTag {
    opacity: 0.85;
    color: black;
    margin-bottom: 6px;
    display: block;
  }
  .loginField {
  }
}
.inlineForm {
  margin-top: 30px;
  .labelTag {
    position: absolute;
    top: -23px;
    z-index: 1;
    margin: inherit;
  }
}


//select box and text box setting
.MuiFormControl-root {
  .MuiOutlinedInput-root {
    background-color: #fff;
    height: $height;
  }

  .MuiInputLabel-root,
  svg {
    color: rgb(13, 13, 13);
  }

  /*for transparent SelectBox*/
  &.transparent {
    .MuiOutlinedInput-root {
      background-color: transparent;
    }
    fieldset {
      border: 1px solid #fff !important;
    }
    .MuiInputLabel-root,
    svg {
      color: #fff;
    }
  }

  /*for no-border SelectBox*/
  &.no-border {
    fieldset {
      border: 0px solid #fff !important;
    }
  }

  /*for no-border SelectBox*/
  &.darkcolor {
    .MuiInputLabel-root,
    svg {
      color: rgb(3, 2, 2);
    }
  }
}

.company-select {
  .css-mmiro1-MuiFormLabel-root-MuiInputLabel-root{
    overflow: visible;
  }
  .MuiOutlinedInput-root {
    height: 40px !important;
  }
  .MuiInputLabel-root {
    line-height: 0.85em;
    overflow: visible;
  }
}

//SearchBox
.SearchBox {
  width: 100%;
  position: relative;
  button {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 7px;
    margin: auto;
    height: 30px;
    width: 30px;
  }
  .MuiInputLabel-root {
    width: 100%;
    left: 0;
    padding-left: 30px;
    color: rgb(132, 124, 124);
  }
  .MuiInputBase-input {
    width: 100%;
    left: 0;
    padding-left: 40px;
  }
}
.switchBtn {
  margin: 6px 0px 8px;
}
.sign-in-text {
  margin-bottom: 10px !important;
}
