@import "./variables";
.sidebar {
  min-height: calc(100vh - 24px);
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 12px 32px rgba(162, 171, 200, 0.15);
  border-radius: $radius;
  padding: 30px 15px;
  .logo-container {
    img {
      width: 100%;
    }
    img.logo-underline {
      margin: 0px 0px;
    }
  }
  .menu {
    padding-left: 0px;
    li a {
      padding: 10px;
      display: block;
      text-transform: capitalize;
      &.active {
        background: #ffffff;
        border: 1px solid rgba(188, 192, 221, 0.25);
        box-sizing: border-box;
        box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        .navText {
          color: #000;
        }
      }
      svg, path {
        margin-right: 10px;
        color: $primary-icon-color;
        stroke: $primary-icon-color;
      }
    }
    .divider {
      margin: 10px 0px 12px 0px;
      border-bottom: 2px solid #eef3ff;
    }
  }

  a {
    color: rgb(43, 36, 36);
    text-decoration: none;
  }

  &.presentationSidebar {
    .menu li a {
      display: block;
      padding: 0px;
      text-align: center;
      margin-bottom: 20px;
      svg {
        margin: 0px;
      }
      span {
        display: block;
      }
    }
  }
}
