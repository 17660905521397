#login-background {
  background-image: url("../assets/images/login-bg.png");
  height: calc(100vh - 30px);
  width: 95%;
  margin: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  background-position: center;
}
.FormControl {
  margin-bottom: 15px;
  .label {
    position: relative;
    top: 0;
  }
}
.FormControl .login-page {
  height: 100vh;
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
.login-form-container {
  width: 80%;
  margin: auto;
  .logo {
    display: block;
    width: 30%;
    margin: 0 auto;
  }
  .login-heading {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 60px;
  }
  .sign-in-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
  }
  .forgot-password {
    color: #2cc1d6;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: underline;
  }
}
.copy-right {
  p {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    a {
      color: #2cc1d6;
      font-weight: 900;
    }
  }
}