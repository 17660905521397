@import "src/styles/variables";
$itemPadding: 16;
$itemTransition: all 300ms ease;
.cardItem {
  //all cardItem css
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
  transition: $itemTransition;
  box-shadow: 0px 12px 32px rgba(162, 171, 200, 0.15);
  &:hover {
    box-shadow: 0px 8px 20px rgb(162 171 200 / 42%);
  }
  .image-wrap {
    margin-bottom: 10px;
    aspect-ratio: 1/0.8;
    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 3px;
      object-fit: cover;
    }
  }
  .subtitle {
    color: $color4;
  }
  .title {
    color: $primary-text-color;
    text-align: left;
    transition: $itemTransition;
  }
  .templateTitle {
    background: #2844b7;
    color: #fff;
    padding: 10px;
    border-radius: 9px;
    text-align: center;
    font-weight: 500;
    font-size: 15px !important;
    letter-spacing: 1px;
  }
  .description {
    color: #a0aec0;
  }
  .controls {
    margin: 10px 0px 4px;
    a {
      margin-right: 10px;
    }
    button {
      border: none;
      height: 32px;
      width: 32px;
      left: 0px;
      top: 0px;
      border-radius: 4px;
      // background-color: #e3fbff;
      color: #2cc1d6;
      cursor: pointer;
      margin-right: 10px;
      line-height: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  /*presnetation chapter card */

}
