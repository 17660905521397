.contentHead {
  margin-bottom: 10px;
  .left {
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;
      color: #2d3748;
      margin: 0px;
    }
    .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #4f4f4f;
      opacity: 0.6;
      margin-top: 0px;
    }
  }
  .right {
    text-align: right;
  }
}
