@import "layout";
@import "variables";
// @import "cardItem";
@import "form";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&display=swap");
body {
  font-family: "Montserrat";
  height: 100vh;
  #root {
    height: 100%;
  }
}

// If you want to add custom CSS you can put it here.
* {
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color6;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
$column-padding: 10px;
ul li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.bgline {
  background-image: url("../assets/images/logo-underline.png");
  background-size: cover;
  width: 100%;
  height: 2px;
  display: block;
  margin-top: 15px;
  margin-bottom: 20px;
}
.hide {
  display: none;
}
@mixin button {
  padding: 5px 10px !important;
  font-size: 12px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}
.btn-primary {
  @include button;
  background-color: $color3 !important;
  color: #fff;
}
.btn-primary svg {
  margin-right: 10px;
}
.btn-secondary {
  @include button;
  background-color: transparent !important;
  color: $color3 !important;
  border: 1px solid $color3 !important;
}
.btn-secondary svg {
  margin-right: 10px;
}
@media (min-width: 1536px) {
  .custom-width {
    max-width: 20% !important;
    flex-basis: 20% !important;
  }
}
.form-card {
  padding: 1rem;
  margin-bottom: 1rem;
}

.form-heading {
  background: #f2f2f2;
  margin-bottom: 1rem;
  border-radius: 7px;
  padding: 5px 11px;
}

// Switch Styles
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ba03008f;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0092a6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0092a6;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.custom-select-box {
  width: 380px !important;    
    float: right; 
}
.delete-button {
  display: flex;
    flex-direction: row-reverse;
}