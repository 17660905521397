.uploadPresentaion {
    width: 40%;
    margin: auto;
  }
  .dialogBody {
    .uploadHead {
      color: rgba(44, 46, 116, 1);
    }
    .uploadImage {
      display: block;
      margin: auto;
      width: auto;
      height:15vh
    }
    .uploadArea {
      box-sizing: border-box;
      background-color: rgba(227, 251, 255, 1);
      border-radius: 20px;
      padding: 1rem;
      cursor: pointer;
      .heading {
        font-size: 30px;
        font-weight: 700;
        color: rgba(44, 46, 116, 1);
      }
      .subHeading {
        font-size: 14px;
        color: #a0aec0;
      }
    }
    .divider {
      width: 95%;
      margin: 16px auto;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(188, 192, 221, 0) 0%,
        #bcc0dd 20.11%,
        #bcc0dd 80.43%,
        rgba(188, 192, 221, 0.15625) 99.04%
      );
    }
    .cancelBtn,
    .uploadBtn {
      float: right;
      margin-left: 10px !important;
      .MuiButton-startIcon {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  .dragOver {
    background-color: rgb(64, 226, 255) !important;
    border: 3px dotted aliceblue !important;
  }
  