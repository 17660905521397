$box-width: 372px;

.collapse_details {
  margin-left: 30px;
}

.accordion_summary {
  border-bottom: 1px solid gray !important;
}

.package_action {
  text-align: left;
}

.package_switch_btn {
  display: flex;
  justify-content: flex-end;
}

.packages_accordian_label,
.switch_button_label {
  font-size: 14px !important;
  color: rgb(95, 95, 95) !important;
}

.company-permission-edit-page {
  .company-permission-edit {
    position: relative;
    overflow: auto;
    height: 66vh;
    background-color: white;
    padding: 10px;
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
    padding-top: 30px;

    .permission-maincontainer{
      border: solid 1px rgb(191, 191, 191);
      width: 100%;
      border-radius: 8px;
      min-height: 290px;
      overflow: hidden;
      .permission-maincontainer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        border-bottom: solid 1px #00394126;
        color: #4c4c4c;
        background-color: #005a6721;
      }
      .permission-maincontainer-body {
        padding: 0px 15px;
        color: #003941a3;

        .MuiFormControlLabel-labelPlacementStart {
          width: 48%;
          display: flex;
          justify-content: space-between;
          margin-left: 0;
          padding: 10px 0;

          &:not(:last-child) {
            border-bottom: 1px solid #00394126;
          }
        }
      }
    }

    .permission-container {
      display: inline-block;
      width: 48%;
      border: solid 1px rgb(191, 191, 191);
      border-radius: 8px;
      // min-height: 290px;
      overflow: hidden;
      margin: 10px;

      &.two-width {
        width: calc( 96% + 22px);
        .permission-container-body {
          .MuiFormControlLabel-labelPlacementStart {
            width: 48%;
            display: inline-flex;

            &:nth-child(even) {
              margin-left: 5%;
            }
          }
        }
      }

      .permission-container-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        border-bottom: solid 1px #00394126;
        color: #4c4c4c;
        background-color: #005a6721;
      }

      .permission-container-body {
        padding: 0px 15px;
        // padding-top: 5px;
        color: #003941a3;

        .MuiFormControlLabel-labelPlacementStart {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-left: 0;
          padding: 10px 0;

          &:not(:last-child) {
            border-bottom: 1px solid #00394126;
          }
        }
      }
    }
  }
  
  .permission-body {
    padding: 0px 50px 0px 15px;
    // padding-top: 5px;
    width: 20%;
    color: #003941a3;

    .MuiFormControlLabel-labelPlacementStart {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 0;
      padding: 15px 0;
    }
  }

  .permission-btn-row {
    padding-right: 15px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
