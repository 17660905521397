.mainCol {
  padding: 12px;
  &:last-child {
    padding-left: 0px;
  }
}
body {
  background-color: #f7fafc;
}
.bgImge2 {
  position: absolute;
  left: 0;
  right: -100.62%;
  top: 0%;
  bottom: 65.73%;
  background-image: url("../assets/images/bg-home2.jpg");
  background-blend-mode: overlay;
  background-color: #627a7e;
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 354px;
  z-index: -1;
}
